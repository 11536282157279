/* ------------------------------------ Font ------------------------------------  */
@font-face {
  font-family: 'ibm_regular';
  src: url('./assets/font/ibm/IBMPlexSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ibm_italic';
  src: url('./assets/font/ibm/IBMPlexSans-Italic.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ibm_mono';
  src: url('./assets/font/ibm/IBMPlexMono-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'mokoto';
  src: url('./assets/font/mokoto/glitch-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'mokoto_glitch_outline';
  src: url('./assets/font/mokoto/glitch_mark_outline-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'mokoto_glitch_mk1';
  src: url('./assets/font/mokoto/glitch_mark_1-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'mokoto_glitch_mk2';
  src: url('./assets/font/mokoto/glitch_mark_2-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

/* --------------------------------------------------------------------------------*/
html {
  --imp-text-purple : rgb(161, 126, 255);
  --imp-text-blue: #37f5f5;
  --imp-text-default: white;
}

.default {
  color: var(--imp-text-default);
  text-shadow: 0 0 15px #10001e;
}

.blue {
  color: var(--imp-text-blue);
  text-shadow: 0 0 15px #10001e;
}

.purple {
  color: var(--imp-text-purple );
  text-shadow: 0 0 15px #10001e;
}

#tsparticles {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.neon {
  background: #8134CF;
  background: -webkit-radial-gradient(circle farthest-side at top center, #8134CF 15%, #37f5f5 82%);
  background: -moz-radial-gradient(circle farthest-side at top center, #8134CF 15%, #37f5f5 82%);
  background: radial-gradient(circle farthest-side at top center, #8134CF 15%, #37f5f5 82%);
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.glitch {
  font-family: 'mokoto_glitch_mk2', sans-serif;
}

.noglitch {
  font-family: 'mokoto', sans-serif;
}

body {
  position: relative;
  margin: 0;
  font-family: 'ibm_regular', sans-serif;
  color: var(--imp-text-default);
  background: #0f0c29;
  background: -webkit-linear-gradient(to right, #2a243e, #302b63, #170c29);
  background: linear-gradient(to right, #2a243e, #302b63, #170c29);

}

a {
  text-decoration: none;
  color: inherit;
}

.img-fluid {
  max-height: 100%;
}

.img-avatar {
    max-width: 70%;
}

.heading {
  font-family: 'mokoto_glitch_mk2', sans-serif;
  display: inline-block;
  font-size: 2em;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
}

.content {
  padding: 9rem 0 2rem !important;
  color: whitesmoke;
  text-align: left;
}

/* ------------------------------------preloader------------------------------------  */
#preloader {
  background-color: rgb(29, 21, 36);
  background-image: url(./assets/img/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
}

#preloader-none {
  display: none;
}

#solar-system-loader {
  max-width: 50%;
  height: auto;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* ------------------------------------ Navbar------------------------------------  */
.sticky {
  /*background-color: #40375eaf;*/
  background-color: rgba(0, 0, 0, 0.400);
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171);
  /*backdrop-filter: blur(15px);*/
  transition: all 0.5s ease 0s;
}

.navbar {
  position: fixed;
  padding: 0.3rem 2rem;
  font-size: 1.2rem;
  font-family: 'ibm_mono', sans-serif;
}

.navbar-brand {
  color: #ffffff;
}

.logo {
  height: 6vw;
  width: 6vw;
  min-height: 50px;
  min-width: 50px;
  max-height: 150px;
  max-width: 150px;
}

.navbar-nav .nav-link {
  color: #ffffff;
  padding-right: 1rem;
  padding-left: 1rem;
}

.nav-link {
  padding: 0.8rem 1rem;
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item .nav-link-custom {
  font-weight: 500;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
  display: inline-block;
  font-size: 1.4rem;
  color: white;
}

.nav-link-custom .nav-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease 0s;
  width: 30px;
  height: 30px;
  margin-bottom: 4px; 
  margin-right: 10px;
  font-size: 2.5rem;
  color: var(--imp-text-blue);
}

.nav-link-custom .nav-icon::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.nav-link-custom:hover .nav-icon {
  box-shadow: 0 0 15px var(--imp-text-blue);
}

.navbar-nav .nav-link.active, .navbar-nav .show >.nav-link {
  color: var(--imp-text-blue);
}

.dropdown-menu{
  /*,*/
  background-color: rgba(0, 0, 0, 0.400);
  backdrop-filter: blur(15px);
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.583);
}

.dropdown-item{
  color: white;
  font-family: 'ibm_mono', sans-serif;
  font-size: 1.4rem;
}

.dropdown-item:hover {
  color: var(--imp-text-blue);
  background-color: transparent;
}

@media (max-width: 1024px) {
  .navbar {
    padding: 1rem 2rem;
    font-size: 1.5rem;
    /*background-color:  #4c4468af;*/
    background-color: rgba(0, 0, 0, 0.400);
    backdrop-filter: blur(10px);
  }

  .navbar-toggler {
    position: relative;
    background-color: transparent;
    border-color: transparent;
  }
  
  .navbar-toggler span {
    display: block;
    background-color: var(--imp-text-blue);
    height: 3px;
    width: 27px;
    margin-top: 5px;
    margin-bottom: 5px;
    transform: rotate(0deg);
    left: 0;
    opacity: 1;
  }
  
  .navbar-toggler:focus,
  .navbar-toggler:active {
    outline: 0;
    box-shadow: none;
    border: 0;
  }
  .navbar-nav .nav-item .nav-link-custom {
    font-size: 1.4rem;
  }

  .dropdown-item {
    color: white;
    font-family: 'ibm_mono', sans-serif;
    font-size: 1.1rem;
  }

  .navbar-toggler span:nth-child(1),
  .navbar-toggler span:nth-child(3) {
    transition: transform 0.35s ease-in-out;
    transition: transform 0.35s ease-in-out;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute;
    left: 12px;
    top: 10px;
    transform: rotate(135deg);
    opacity: 0.9;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px;
    visibility: hidden;
    background-color: transparent;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute;
    left: 12px;
    top: 10px;
    transform: rotate(-135deg);
    opacity: 0.9;
  }
  
  .navbar-nav .nav-item {
    margin-left: 30px;
  }
  
  .navbar-nav .nav-link {
    font-size: 1.5rem;
  }
  
  .navbar-brand {
    font-size: 1.7rem;
  }
  
  .logo {
    height: 7vw;
    width: 7vw;
  }
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem;
    font-size: 1.8rem;
  }
  
  .navbar-toggler {
    font-size: 1.4rem;
  }

  .navbar-nav .nav-item a::after {
    display: none;
  }

  .nav-link {
    padding: 0.7rem 1rem;
  }

  .navbar-nav .nav-item {
    margin-left: 40px;
  }
  
  .navbar-nav .nav-link {
    font-size: 1.8rem;
  }
  
  .navbar-brand {
    font-size: 2rem;
  }
  
  .logo {
    height: 10vw;
    width: 10vw;
  }
}

/* ------------------------------------ Home -----------------------------------------  */
.home-section {
  padding-bottom: 30px;
  padding-top: 30px;
  position: relative;
  z-index: 1;
}

.home-description {
  color: #e8e1fb;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.517);
  border-radius: 10px;
  /*box-shadow: 4px 5px 4px 3px rgb(89 4 168 / 14%);*/
  border: 2px solid rgba(200, 137, 230, 0.637);
  font-family: 'ibm_mono', sans-serif;
}

.home-description-title {
  font-family: 'mokoto', sans-serif;
  text-shadow: 0 0 15px #10001e;
  font-size: 1.5rem;
  padding-top: 15px;
  padding-bottom: 10px;
}

.home-description-body {
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 1.5em;
  font-weight: 500;
}

.home-header {
  font-family: 'mokoto', sans-serif;
}

/*.heading {*/
/*  font-size: 2.5rem;*/
/*}*/

@media (min-width: 768px) {
  .home-header .heading {
    font-size: 2.4rem;
  }
}
/* -------------------------------------------  */

.home-about-section {
  padding-bottom: 70px;
  padding-top: 70px;
  background-image:  url(./assets/background/aqua.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
}

.home-terminal {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.writing-container {
  text-align: left;
  background-color: #000;
  color: #0f0;
  padding: 20px;
  border: 5px solid rgba(200, 137, 230, 0.637);
  border-radius: 7px;
  font-family: 'Courier New', Courier, monospace;
  width: 100%;
  max-width: 800px;
  white-space: pre-wrap;
  overflow-x: auto;
}

.myAvatar {
  width: 70%;
  height: auto;
}

.home-image {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.avatar {
  border-radius: 50%;
}

.icon-colour {
  color: white;
  text-align: center;
}

.home-about-social-links {
  padding-top: 15px;
  display: inline-block;
  position: relative;
}


.home-social-icons {
  margin-top: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 20px;
  background: rgb(96, 71, 139);
  border-radius: 50%;
  transition: 0.5s;
  color: white;
}


.home-social-icons::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

a:hover {
  color: var(--imp-text-blue);
}

.home-social-icons:hover {
  box-shadow: 0 0 15px var(--imp-text-blue);
}

@media screen and (min-width: 768px) {
  .home-about-description {
    align-self: center;
  }
}

/* ------------------------------------ About-------------------------------------------  */
.about-section {
  padding-bottom: 30px;
  padding-top: 30px;
  position: relative;
  z-index: 1;
}

.about-row {
  justify-content:center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.about-img {
  padding-bottom: 15px;
}

.about-funny {
  justify-content: center;
}

.about-card-list {
  margin: 10px;
  color: white;
}


/* -------------------card-----------------------  */
.quote-card-view {
  background-color: rgba(15, 11, 20, 0.775);
  border: 2px solid rgba(200, 137, 230, 0.637);
  border-radius: 10px;
  box-shadow: 4px 5px 4px 3px rgb(89 4 168 / 14%);
  padding: 1em;
}

.about-card-title {
    font-family: 'ibm_mono', sans-serif;
    text-align: center;
    line-height: 1.5;
    margin-bottom: 20px;
    font-weight: bold;
}

.about-card-body {
  text-align: left;
  line-height: 1.5;
  color: white;
}

.about-card-body p {
  font-size: 1.5rem;
  font-weight: 500;
}

.about-activity {
  color: white;
  list-style: none;
  text-align: left;
  padding-left: 1px;
  font-size: 1.4rem;
}
.quote {
  font-family: 'ibm_italic', sans-serif;
  font-size: 1.2rem !important;
  padding-left: 3rem;
  padding-top: 10px;
}

/* -------------------skills-----------------------  */

.competency-section {
  padding: 3em 0 1em;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.tech-stack-container {
  justify-content: center;
  padding-bottom: 50px;
}

.tech-icons {
  font-size: 4.5em;
  margin: 15px;
  padding: 10px;
  border: 2px solid rgba(200, 137, 230, 0.637);
  border-radius: 5px;
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137);
  display: table;
  text-align: center;
  vertical-align: middle;
  transition: all 0.4s ease 0s;
  background-color: rgba(0, 0, 0, 0.566);
  position: relative;
  overflow: visible;
}

.tech-icons:hover {
  transform: scale(1.1);
}

.tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, -10px);
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 16px;
  white-space: nowrap;
  background-color: rgb(150, 75, 221);
  color: white;
  text-shadow: 1px 1px 1px rgba(200, 137, 230, 0.637);
  z-index: 10;
  opacity: 0;
}

.tech-icons:hover .tooltip {
  opacity: 1;
}

/* ------------------------------------ Projects -----------------------------------------  */

.project-section {
  padding-top: 160px;
  padding-bottom: 30px;
  background-size: cover;
  color: white;
  font-family: 'ibm_mono', sans-serif;
}

.project-card {
  padding: 3em 1.5em;
  height: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.project-card-container {
  display: flex;
  align-items: stretch;
  margin-bottom: 20px;
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 20px;
}

.project-card-view {
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.14);
  background-color: rgba(0, 0, 0, 0.517);
  transition: all 0.5s ease 0s;
  border-radius: 10px;
  color: white;
  height: 100%;
}

.project-card-view:hover {
  box-shadow: 1px 1px 5px rgb(55, 245, 245), -5px -5px 15px rgb(129, 52, 207);
  transform: scale(1.1);
}

.card-img-top {
  padding: 20px;
  border-radius: 30px;
  width: 100%;
}

.project-details-link {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #7979e4;
  font-weight: bold;
  text-decoration: none;
  padding-top: 10px;
}

.project-details-link > a {
  font-size: 30px;
}

.project-details-link:hover {
  color: var(--imp-text-blue);
}

.card-title {
  font-size: 1.5rem;
  font-weight: 700;
  padding-bottom: 10px;
}

/* -----------------------Details-----------------------------  */

.back-button {
  margin-top: 20px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 30px;
  transition: 0.5s;
  color: white;
}

.back-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.details-section {
  padding-bottom: 30px;
  background-size: cover;
  color: white;
  position: relative;
  z-index: 1;
}

.details {
  text-align: center;
}

.details-title {
  font-family: 'mokoto', sans-serif;
  color: rgb(103, 63, 215);
  font-size: 1.7rem;
  padding-top: 15px;
  padding-bottom: 10px;
}

.description-bloc {
  background-color: rgba(15, 11, 20, 0.4);
  border-radius: 12px;
  border: 2px solid rgba(200, 137, 230, 0.637);
  box-shadow: 4px 5px 4px 3px rgb(89 4 168 / 14%);
}

.project-desc {
  font-size: 1.4rem;
  font-weight: 500;
  padding-bottom: 20px;
  line-height: 1.8;
  color: white;
}

.project-desc-link {
  padding-top: 15px;
  padding-bottom: 30px;
  position: relative;
}

.project-img{
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}

.details-image {
  border-radius: 12px;
}

.stack-bloc {
  color: #e8e1fb;
  padding-top: 10px;
}

.details-icons {
  padding: 10px 20px;
  border: 2px solid rgba(200, 137, 230, 0.637);
  border-radius: 5px;
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137);
  text-align: center;
  transition: all 0.4s ease 0s;
  background-color: rgba(0, 0, 0, 0.566);
  font-size: 2.5rem;
  box-sizing: border-box;
}

.project-icons  {
  margin-top: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 20px;
  background: rgb(59, 44, 85);
  border-radius: 50%;
  transition: 0.5s;
  color: white;
}

.project-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.project-icons:hover {
  box-shadow: 0 0 15px var(--imp-text-blue);
}

@media (max-width: 576px) {
  .project-card {
    padding: 1em;
  }

  .project-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  .card-img-top {
    padding: 10px;
  }

  .project-details {
    justify-content: center;
    margin-top: 0.5em;
  }

  .project-details-link {
    font-size: 14px;
  }
}
/* ------------------------------------ Contact -----------------------------------------  */

.container-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contact-card {
  width: 60%;
  text-align: center;
  padding: 10px;
  background-color: rgba(15, 11, 20, 0.671);
  border: 2px solid rgba(200, 137, 230, 0.637);
  border-radius: 10px;
  box-shadow: 4px 5px 4px 3px rgb(89 4 168 / 14%);
  /*box-shadow: 1px 1px 5px rgb(0, 255, 94), -5px -5px 15px  rgb(55, 245, 245);*/
}

.profileimage {
  background-color: transparent;
  border: none;
  margin-top: 20px;
  border-radius: 5em;
  text-align: center;
}

.profile {
  border-radius: 50%;
  width: 90%;
  background-image:  url(./assets/background/aqua.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-card-info {
  padding-top: 20px;
  font-size: 1.2rem;
}

.contact-bar {
  display: inline-block;
  margin-bottom: 1rem;
  margin-top: 1rem;
  border-radius: 3em;
  padding: 14px;
  background-color: rgba(44, 32, 59, 0.593);
  box-shadow: 3px 3px 15px rgb(74, 46, 99),
    -3px -3px 15px rgb(121, 75, 165);
}

.contact-icon {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 20px;
  background: rgb(96, 71, 139);
  border-radius: 50%;
  transition: 0.5s;
  color: white;
}

.contact-icon:hover {
  box-shadow: 0 0 15px var(--imp-text-blue);
}

.bloc-text {
  color: #e8e1fb;
  font-family: 'ibm_italic', sans-serif;
  text-align: center;
  background: rgba(41, 36, 43, 0.35);
  border-radius: 10px;
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137);
}

.contact-title {
  font-family: 'mokoto', sans-serif;
  font-size: 1.5rem;
  padding-top: 15px;
  margin: 0;
}

.contact-section {
  padding-bottom: 30px;
  background-size: cover;
  color: white;
  position: relative;
  z-index: 1;
}
/* --------------------------------------  */

.container-form {
  background-color: rgba(15, 11, 20, 0.671);
  border-radius: 10px;
  /*box-shadow: 1px 1px 5px rgb(0, 255, 94), -5px -5px 15px  rgb(55, 245, 245);*/
  padding: 10px;
  border: 2px solid rgba(200, 137, 230, 0.637);
  box-shadow: 4px 5px 4px 3px rgb(89 4 168 / 14%);
}

.form-contact {
  padding: 50px;
  font-family: 'ibm_mono', sans-serif;
}

.form-control {
  background-color: rgba(84, 73, 98, 0.593);
  border: none;
  padding: 14px;
  color: white;
  font-size: 1.2em;
  background: linear-gradient(90deg, rgba(255, 254, 250, 0) 0%, rgba(55, 245, 245, 0.543) 27%, rgba(102, 224, 255, 0.3) 63%, rgba(255, 255, 255, 0) 100%),     linear-gradient(0deg, rgba(102, 224, 255, 0.3) 0%, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0) 96%, rgba(102, 224, 255, 0.3) 100%);
  
}

.form-control:focus {
  background-color: rgba(44, 32, 59, 0.593);
  color: white;
}

.form-control::placeholder {
  color: white;
}

.success {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 70px;
  margin-bottom: 50px;
}

.error {
  display: flex;
  justify-content: center;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .contact-section {
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
  }

  .contact-section .contact-card {
    width: 100% !important;
    margin: auto;
  }
}
/* ------------------------------------ Footer---------------------------------------------*/

.footer {
  position: absolute;
  /*background-color: #40375eaf;*/
    background-color: rgba(0, 0, 0, 0.400);
}

.footer-row {
  justify-content: center;
}

.footer-copywright{
  margin-top: 1em;
}

.footer h3 {
  color: var(--imp-text-blue);
  text-shadow: 0 0 20px #000000;
  font-size: 1rem;
  font-family: 'ibm_mono', sans-serif;
}

.social-icons {
  display: inline-block;
  position: relative;
}

.footer-social-icons {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  font-size: 1.2rem;
  transition: 0.5s;
  color: white;
}

.footer-social-icons::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

@media (max-width: 767px) {

  .footer-copywright {
    text-align: center;
  }

  .footer-body {
    text-align: center;
  }
}

/* ------------------------------------ Animation ------------------------------------  */
.wave {
  animation-name: wave-animation;
  animation-duration: 2.1s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
  font-size: 4rem;
  padding-left: 15px;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.glow-on-hover {
  font-family: 'mokoto', sans-serif;
  border: none;
  outline: none;
  background: rgb(96, 71, 139);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 6px;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #17CF79, #5FFBF1,#8134CF,#D8ACFF, #D16BA5, #eaafc8,#845EC2);
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background:rgba(48, 37, 52, 0.243);
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}
